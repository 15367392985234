<template>
  <div>
    <FiltriAttestatiQualifiche
      @getAttestatiQualificheList="getAttestatiQualificheList"
      @resetFilters="resetFilters"
    />
    <TableAttestatiQualifiche
      @getAttestatiQualificheList="getAttestatiQualificheList"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  getCurrentInstance,
  ref,
} from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

import FiltriAttestatiQualifiche from "@/components/components-fit/SNM/AttestatiQualifiche/FiltriAttestatiQualifiche.vue";
import TableAttestatiQualifiche from "@/components/components-fit/SNM/AttestatiQualifiche/TableAttestatiQualifiche.vue";

export default defineComponent({
  name: "AttestatiQualifiche-snm",
  components: {
    FiltriAttestatiQualifiche,
    TableAttestatiQualifiche,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Gestione Attestati", []);
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    // const stagione = computed(() => store.getters.stagioneSelected);
    const rowsToSkip = computed(
      () => store.getters.rowsToSkipAttestatiQualifiche
    );
    const fetchRows = computed(
      () => store.getters.fetchRowsAttestatiQualifiche
    );

    const id_persona = computed(
      () => store.getters.id_personaAttestatiQualifiche
    );
    const idQualificaAlboAttestatiQualifiche = computed(
      () => store.getters.idQualificaAlboAttestatiQualifiche
    );

    const tipo_qualifica = computed(
      () => store.getters.tipo_qualificaAttestatiQualifiche
    );

    const getAttestatiQualificheList = () => {
      store.dispatch("setStoreListData", {
        keys: {
          id_qualifica: idQualificaAlboAttestatiQualifiche.value,
          id_persona: id_persona.value,
          tipo_qualifica: tipo_qualifica.value,
          rowstoskip: rowsToSkip.value,
          fetchrows: fetchRows.value,
        },
        apiLink: globalApi.SNM_CORSI_PERSONE_GESTIONE_ATTESTATI_LIST,
        itemName: "SNM_CORSI_PERSONE_GESTIONE_ATTESTATI_LIST",
      });
    };
    const resetted = ref(false);
    getAttestatiQualificheList();

    const resetFilters = () => {
      store.commit("resetFiltersAttestatiQualifiche");
      resetted.value = true;
      setTimeout(() => {
        resetted.value = false;
      }, 1000);
      getAttestatiQualificheList();
    };

    const loaded = computed(() =>
      store.getters.getStateFromName(
        "loadedSNM_CORSI_PERSONE_GESTIONE_ATTESTATI_LIST"
      )
    );

    const searchAlboList = () => {
      if (!loaded.value) return;
      store.commit("setRowsToSkipAlbo");
      getAttestatiQualificheList();
    };

    return {
      getAttestatiQualificheList,
      resetFilters,
      resetted,
      searchAlboList,
    };
  },
});
</script>
